import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {
    WindowService,
    TokenService,
    UserService,
    ErrorhandlerService,
    AppEventsService,
    AppRouteService, CartService
} from '../../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'coop-user-verification',
    templateUrl: './user-verification.component.html',
    styleUrls: ['./user-verification.component.scss']
})
export class UserVerificationComponent implements OnInit, OnDestroy {
    token;
    appDataCounter = true;
    private _subscriptionState = true;

    constructor(private _renderer: Renderer2,
                private _window: WindowService,
                private _userService: UserService,
                private _errorhandlerService: ErrorhandlerService,
                private _appEventService: AppEventsService,
                private _routerService: AppRouteService,
                private _cartService: CartService,
                private _router: Router,
                private _route: ActivatedRoute
    ) {
        this._window.window['AppData'] = (appData) => {
            this._window.window.dispatchEvent(new CustomEvent('token-received', {
                detail: {
                    token: appData.jwt,
                    paddingTop: appData.topInset,
                    paddingBottom: appData.bottomInset
                },
                bubbles: false,
                composed: true
            }));
        };
        this._renderer.listen('window', 'token-received', (event) => {
            if (this.appDataCounter) {
                this.appDataCounter = false;
                this.appDataReceived(event);
            }
        });
    }

    ngOnInit(): void {

        if (!environment.production) {
            const verifyToken = this._window.window['jwt'];
            if (verifyToken) {
                this._window.window['AppData']({
                    jwt: verifyToken,
                    topInset: 44,
                    bottomInset: 34
                });
            }
        }

    }

    ngOnDestroy(): void {
        this._subscriptionState = false;
    }

    appDataReceived(event: CustomEvent): void {
        this.token = event.detail['token'];
        if (this.token.startsWith('Bearer')) {
            this.token = this.token.replace('Bearer ', '');
        }
        const decodedToken = this.parseJwt(this.token);
        this.setAppData(decodedToken, event.detail['paddingTop'], event.detail['paddingBottom']);
        this._handleAppRouting();
    }

    private _handleAppRouting(): void {
        this.routeApp(TokenService.storeKardex);
    }

    private setAppData(decodedToken: object, pTop, pBottom): void {
        TokenService.jwtToken = this.token;
        TokenService.customerId = decodedToken['membershipnr'];
        TokenService.subId = decodedToken['sub'];
        TokenService.paddingTop = pTop;
        TokenService.paddingBottom = pBottom;
    }

    parseJwt(token): object {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    private routeTo(url: string): void {
        this._router.navigate([url]).then();
    }

    routeApp(storeKardex: string): void {
        if (!storeKardex || storeKardex === '-1') {
            TokenService.storeKardex = '-1';
            this.routeTo('/coop/coming-soon');
            return;
        }
        if (storeKardex) {
            // din butik
            if (TokenService.openTab) {
                if (TokenService.openTab === 'LocalFood') {
                    this._setActiveTab('0');
                    this.routeTo(`/coop/cart-verify`);
                }
                if (TokenService.openTab === 'LocalNewsOffers') {
                    this._setActiveTab('2');
                    this.routeTo(`/coop/iframe-content/${TokenService.activeTab}`);
                }
                if (TokenService.openTab === 'LocalEventsPolls') {
                    this._setActiveTab('1');
                    this.routeTo(`/coop/iframe-content/${TokenService.activeTab}`);
                }
            }
            if (!TokenService.openTab) {
                this._setActiveTab('2');
                this.routeTo(`/coop/iframe-content/${TokenService.activeTab}`);
            }
        }
    }

    private _setActiveTab(activeTab: string): void {
        TokenService.activeTab = activeTab;
    }

}
